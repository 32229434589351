import React from "react";
import SEO from "../components/seo";
import { useTheme, Theme, Container, Grid, Box, Typography, Button } from "@material-ui/core";
import { graphql, Link } from "gatsby";
import { RoundedImg, UnderlineOnHoverLink } from "../components/styledComponents";
import styled from "@emotion/styled";
import BlogTagAndDate from "../components/blog-tag-and-date";
import Section from "../components/section";
import BlogList from "../components/blog-list";
import Pagination from "../components/pagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons';
import MainLayout from "../layouts/mainLayout";

export const pageQuery = graphql`
    query($skip: Int!, $limit: Int!, $filter: MarkdownRemarkFilterInput) {
        blogJson: generalJson (block: { eq: "blog" }) {
            description
            image {
                childImageSharp {
                    resize(width: 750, height: 300, cropFocus: ENTROPY) {
                        src
                        width
                        height
                    }
                }
            }
        }
        allMarkdownRemark(
            sort: { fields: [frontmatter___publish_date], order: DESC }
            limit: $limit
            skip: $skip
            filter: $filter
        ) {
            edges {
                node {
                    excerpt(pruneLength: 400)
                    fields {
                        slug
                        tags: tag_slugs
                    }
                    frontmatter {
                        title
                        tags
                        publish_date(formatString: "MMMM D, YYYY")
                        primary_image {
                            childImageSharp {
                                fluid(maxWidth: 725, maxHeight: 400, cropFocus: ATTENTION) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

interface BlogLandingPageProps {
    location: Location,
    data: {
        blogJson: {
            description: string
            image: import("gatsby-source-filesystem").FileSystemNode;
        },
        allMarkdownRemark: {
            edges: any
        }
    },
    pageContext: {
        numPages: number,
        currentPage: number,
        baseSlug: string,
        tag: string,
    }
}

const CappedRoundedImg = styled(RoundedImg)((props: { theme: Theme }) => `
    height: 375px;

    ${props.theme.breakpoints.down("md")} {
        height: 250px;
    }
`);


const BlogLandingPage: React.FC<BlogLandingPageProps> = (props) => {
    const theme = useTheme<Theme>();
  
    const edges = props.data.allMarkdownRemark.edges;
    const firstPost = edges.slice(0, 1)[0];
    const posts = edges.slice(1);

    return (<MainLayout>
        <SEO title={props.pageContext.tag ? props.pageContext.tag : "Blog"} description={props.data.blogJson.description} image={props.data.blogJson.image} location={props.location} />
        <Container>
            {props.pageContext.tag && <Section tightTop noBottom>
                <Button color="primary" size="large" component={Link} to={"/blog/"}>
                    <FontAwesomeIcon icon={faLongArrowAltLeft} />&nbsp; All Posts
                </Button>
            </Section>}
            {firstPost && <Section tightTop tightBottom>
                <Grid container spacing={4} alignItems="center">
                    <Grid item xs={12} md={7}>
                        <Link to={firstPost.node.fields.slug}>
                            <CappedRoundedImg theme={theme} fluid={firstPost.node.frontmatter.primary_image.childImageSharp.fluid} />
                        </Link>
                    </Grid>
                    <Grid item md={5}>
                        <BlogTagAndDate tagSlugs={firstPost.node.fields.tags} tags={firstPost.node.frontmatter.tags} date={firstPost.node.frontmatter.publish_date} />
                        <Typography variant="h2" component="h2" gutterBottom={true}>
                            <UnderlineOnHoverLink to={firstPost.node.fields.slug}>{firstPost.node.frontmatter.title}</UnderlineOnHoverLink>
                        </Typography>
                        <Typography variant="body1">{firstPost.node.excerpt}</Typography>
                        <Box display="flex" justifyContent="center" pt={2}>
                            <Button variant="contained" color="secondary" to={firstPost.node.fields.slug} component={Link}>Read More</Button>
                        </Box>
                    </Grid>
                </Grid>
            </Section>}
            {posts[0] && <Section tightTop tightBottom>
                <BlogList posts={posts.map(n => n.node)} />
            </Section>}
            <Section tightTop>
                <Box display="flex" justifyContent="center">
                    <Pagination currentPage={props.pageContext.currentPage}
                        totalPages={props.pageContext.numPages}
                        visiblePages={3} rootNav={props.pageContext.baseSlug} />
                </Box>
            </Section>
        </Container>
    </MainLayout>)
}

export default BlogLandingPage