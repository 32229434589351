import { ButtonGroup, Button, Hidden } from "@material-ui/core";
import { Link } from "gatsby";
import { Children } from "react";

interface PageButtonProps {
    isHidden?: boolean;
    isActive?: boolean;
    isDisabled?: boolean;
    linkTo: string;
}

const Wire = ({ children, ...props }) => children(props);

const HiddenPageButton: React.FC<PageButtonProps> = (props) => {
    return (<Wire>
        {p => (
            <Hidden xsDown>
                <PageButton {...p} {...props}>
                    {props.children}
                </PageButton>
            </Hidden>
        )}
    </Wire>)
}

const PageButton: React.FC<PageButtonProps> = (props) => {
    if (props.isHidden)
        return null;

    return (props.isActive ?
        <Button variant="contained"
            size="small"
            color="secondary">
            {props.children}
        </Button>
        : <Button variant="outlined"
            disabled={props.isDisabled || false} component={Link}
            size="small"
            color="primary"
            to={props.linkTo}>
            {props.children}
        </Button>
    );
};

interface PaginationProps {
    currentPage: number;
    totalPages: number;
    visiblePages: number;
    rootNav: string;
}

const Pagination: React.FC<PaginationProps> = (props) => {
    const BASE_SHIFT = 0;
    const TITLE_SHIFT = 1;

    const TITLES = {
        first: 'First',
        prev: '\u00AB',
        prevSet: '...',
        nextSet: '...',
        next: '\u00BB',
        last: 'Last',
    };

    const calcBlocks = () => {
        const total = props.totalPages;
        const blockSize = props.visiblePages;
        const current = props.currentPage;
        const blocks = Math.ceil(total / blockSize);
        const currBlock = Math.ceil(current / blockSize) - TITLE_SHIFT;

        return {
            total: blocks,
            current: currBlock,
            size: blockSize,
        };
    }

    const blocks = calcBlocks();

    const isPrevDisabled = () => props.currentPage <= 1;

    const isNextDisabled = () => props.currentPage >= props.totalPages;

    const visibleRange = () => {
        var start = props.currentPage;
        var end = props.currentPage;
        if (props.currentPage > 1) {
            start--;
        } else if (end < props.totalPages) {
            end++;
        }

        if (end < props.totalPages) {
            end++;
        } else if (start > 1) {
            start--;
        }

        return {
            start: start,
            end: end + TITLE_SHIFT
        };
    }

    const getNavPage = (p: number) => {
        if (p <= 1) {
            return props.rootNav;
        }

        return `${props.rootNav}/${p}`;
    }

    const range = (start: number, end: number) => {
        const res = [];
        for (let i = start; i < end; i++) {
            res.push(i);
        }

        return res;
    }

    const renderPages = (start: number, end: number) => {
        return range(start, end).map((num, idx) => {
            const current = num;
            const isActive = (props.currentPage === current);

            return (
                <PageButton
                    key={idx}
                    isActive={isActive}
                    aria-lable={isActive ? "Current Page" : `Page ${num}`}
                    linkTo={getNavPage(num)}
                >{num}</PageButton>
            );
        });
    }

    const visPages = visibleRange();

    return (
        <ButtonGroup aria-label="pagination" component="nav">
            <HiddenPageButton isDisabled={isPrevDisabled()} linkTo={props.rootNav} aria-label="First Page">
                {TITLES.first}
            </HiddenPageButton>
            <PageButton isDisabled={isPrevDisabled()} linkTo={getNavPage(props.currentPage - 1)} aria-label="Previous Page">
                {TITLES.prev}
            </PageButton>
            <HiddenPageButton isHidden={visPages.start == 1 || blocks.total == 1}
                linkTo={getNavPage(visPages.start - 1)} aria-label="More Previous Pages">
                {TITLES.prevSet}
            </HiddenPageButton>

            {renderPages(visPages.start, visPages.end)}

            <HiddenPageButton isHidden={visPages.end - 1 >= props.totalPages || blocks.total == 1} linkTo={getNavPage(visPages.end)} aria-label="More Next Pages">
                {TITLES.nextSet}
            </HiddenPageButton>
            <PageButton isDisabled={isNextDisabled()} linkTo={getNavPage(props.currentPage + 1)} aria-label="Next Page">
                {TITLES.next}
            </PageButton>
            <HiddenPageButton isDisabled={isNextDisabled()} linkTo={getNavPage(props.totalPages)} aria-label="Last Page">
                {TITLES.last}
            </HiddenPageButton>
        </ButtonGroup>
    );
}

export default Pagination;